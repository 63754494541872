class CreditCardScheme {
  static schemeName = 'generic-card';

  /**
   * Constructs a CreditCardScheme instance with common properties for all schemes.
   * @param {string} inputMask - Mask for formatting the card number.
   * @param {number} minCardNumberLength - Expected min length of the card number.
   * @param {number} maxCardNumberLength - Expected max length of the card number.
   * @param {string} inputMaskDelimiter - Delimiter used in the input mask.
   */
  constructor(
    inputMask,
    minCardNumberLength,
    maxCardNumberLength,
    inputMaskDelimiter,
  ) {
    this.inputMask = inputMask;
    this.minCardNumberLength = minCardNumberLength;
    this.maxCardNumberLength = maxCardNumberLength;
    this.inputMaskDelimiter = inputMaskDelimiter;
    this.subSchemes = [];
  }

  /**
   * Adds a sub-scheme to the current main scheme. This method is used to associate a sub-scheme,
   * such as a specific card type variant, with the main scheme.
   *
   * @param {CreditCardScheme} subScheme - The sub-scheme to be added.
   */
  addSubScheme(subScheme) {
    this.subSchemes.push(subScheme);
  }

  /**
   * Live detects the scheme based on partial card number.
   * Subclasses must implement this method.
   *
   * @param {string} partialCardNumber - Partial card number input.
   * @returns {boolean} - Partial card number is valid.
   * @throws {Error} - Error message indicating the subclass should implement this method.
   */
  liveDetectScheme(partialCardNumber) {
    throw new Error('Subclasses must implement liveDetectScheme method');
  }

  /**
   * Detects the scheme based on full card number.
   * Subclasses must implement this method.
   *
   * @param {string} fullCardNumber - Full card number input.
   * @returns {boolean} - Partial card number is valid.
   * @throws {boolean|Error} - Error message indicating the subclass should implement this method.
   */
  detectScheme(fullCardNumber) {
    throw new Error('Subclasses must implement detectScheme method');
  }

  /**
   * Checks if a card number is valid based on the Luhn algorithm.
   *
   * @param {string} cardNumber - Card number input.
   * @returns {boolean} - True if the card number is valid, otherwise false.
   */
  isValid(cardNumber) {
    const cardNumberLength = cardNumber.length;

    return (
      this.isLengthValid(cardNumberLength) &&
      this.constructor.luhnCheck(cardNumber)
    );
  }

  /**
   * Checks if the given length is valid for this scheme.
   *
   * @param {number} length - Length of the card number.
   * @returns {boolean} - True if valid within min/max bounds.
   */
  isLengthValid(length) {
    return (
      length >= this.minCardNumberLength && length <= this.maxCardNumberLength
    );
  }

  /**
   * Retrieves the input mask for formatting the card number.
   *
   * @returns {string} - Input mask for formatting the card number.
   */
  get getInputMask() {
    return this.inputMask;
  }

  /**
   * Retrieves the scheme name.
   *
   * @returns {string} - The name of the scheme.
   */
  get getSchemeName() {
    return this.constructor.schemeName;
  }

  /**
   * Retrieves the list of sub-schemes associated with this scheme, if any.
   *
   * @returns {array} - An array of sub-scheme instances, or an empty array if none are associated.
   */
  get getSubSchemes() {
    return this.subSchemes;
  }

  /**
   * Calculates the total length of the input mask and card number.
   *
   * @returns {number} - Total length of input mask and card number.
   */
  get getInputMaskCardNumberLength() {
    const delimiterCount =
      this.inputMask.split(this.inputMaskDelimiter).length - 1;
    return delimiterCount + this.maxCardNumberLength; // Assume max for formatting.
  }

  /**
   * Performs the Luhn algorithm check on a card number.
   *
   * @param {string} cardNumber - Card number input.
   * @returns {boolean} - True if the card number is valid, otherwise false.
   */
  static luhnCheck(cardNumber) {
    if (/[^0-9-\s]+/.test(cardNumber)) {
      return false;
    }

    let nCheck = 0,
      bEven = false;
    for (let n = cardNumber.length - 1; n >= 0; n--) {
      let cDigit = cardNumber.charAt(n),
        nDigit = parseInt(cDigit, 10);

      if (bEven && (nDigit *= 2) > 9) {
        nDigit -= 9;
      }

      nCheck += nDigit;
      bEven = !bEven;
    }

    return nCheck % 10 === 0;
  }
}

export default CreditCardScheme;
