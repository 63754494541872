import CreditCardSchemeAbstraction from '../credit-card-scheme-abstraction.js';

class Visa extends CreditCardSchemeAbstraction {
  static schemeName = 'visa-vop';

  constructor(
    inputMask = '#### #### #### ####',
    minCardNumberLength = 16,
    maxCardNumberLength = 16,
    inputMaskDelimiter = ' ',
  ) {
    super(
      inputMask,
      minCardNumberLength,
      maxCardNumberLength,
      inputMaskDelimiter,
    );
  }

  liveDetectScheme(partialCardNumber) {
    // 4 hardcoded and 0-15 digits allowed, exclude 4571 (visa dankort).
    return /^4\d{0,15}$/.test(partialCardNumber);
  }

  detectScheme(cardNumber) {
    return /^4\d{15}$/.test(cardNumber);
  }
}

export default Visa;
