import CreditCardSchemeAbstraction from '../credit-card-scheme-abstraction.js';

class MasterCard extends CreditCardSchemeAbstraction {
  static schemeName = 'mc-cls';

  constructor(
    inputMask = '#### #### #### ####',
    minCardNumberLength = 16,
    maxCardNumberLength = 16,
    inputMaskDelimiter = ' ',
  ) {
    super(
      inputMask,
      minCardNumberLength,
      maxCardNumberLength,
      inputMaskDelimiter,
    );
  }

  liveDetectScheme(partialCardNumber) {
    // part 1:  5 or 5 followed by any digit from 1 to 5, followed by up to 14 additional digits.
    // part 2: 2 followed by any digit from 3 to 6, followed by up to 13 additional digits (e.g. 23... - 26...).
    // part 3: 2 followed by 2, followed by any digit from 2 to 9, followed by up to 13 additional digits (e.g. 22... (min 2221)).
    // part 4: 2 followed by 7, followed by any digit from 0 to 1, followed by up to 13 additional digits.
    //         2 followed by 7, followed by 2, followed by 0, followed by up to 12 additional digits.
    return /^((5|5[1-5]\d{0,14})|(2|2[3-6]|2[3-6][0-9]|2[3-6][0-9][0-9]{0,13})|(22|222|222[1-9]{0,13})|(27|27|27[0-1]\d{0,13}|272|2720\d{0,12}))$/.test(
      partialCardNumber,
    );
  }

  detectScheme(cardNumber) {
    return /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/.test(
      cardNumber,
    );
  }
}

export default MasterCard;
