<script setup>
  import { computed, inject, reactive, readonly, ref, watchEffect } from 'vue';
  import { creditCardDetector } from 'schemes';
  import { useUtility } from '@/composables/use-utility.js';
  const { getDynamicComponent, convertToPascalCase } = useUtility();

  const props = defineProps({
    cardMsg: {
      type: String,
      default: null,
    },
    validState: {
      type: Boolean,
      default: false,
    },
  });

  const translations = inject('translations');

  const emit = defineEmits(['update:validState']);

  const cardNumber = ref('');
  const schemeName = ref(null);

  const inputErrorMsg = ref(null);
  const isCardNumberValidState = ref(false);

  const cardNumberInputMaskDefault = readonly({
    mask: '#### #### #### ####',
    maxLength: 19,
  });

  const cardNumberMaskRules = reactive({ ...cardNumberInputMaskDefault });

  const activeLogoComponent = computed(() =>
    schemeName.value !== null
      ? getDynamicComponent(`Logo${convertToPascalCase(schemeName.value)}`)
      : null,
  );

  const message = computed(() =>
    props.cardMsg ? props.cardMsg : inputErrorMsg.value,
  );

  const stateMsg = computed(() =>
    message.value
      ? translations.getOne(
          message.value,
          'The Card number is invalid/required',
        )
      : null,
  );

  const hasInputError = computed(
    () => !!props.cardMsg || !!inputErrorMsg.value,
  );

  const inputStateSuccess = computed(
    () =>
      (props.validState || isCardNumberValidState.value) &&
      !hasInputError.value,
  );

  const inputStateError = computed(
    () =>
      (!props.validState || !isCardNumberValidState.value) &&
      hasInputError.value,
  );

  watchEffect(() => {
    if (cardNumber.value === '') {
      isCardNumberValidState.value = false;
      schemeName.value = null;
    }

    emit('update:validState', isCardNumberValidState.value);
  });

  // Methods
  async function updateCardNumberOnInput() {
    const cardNumberClean = removeCardNumberWhiteSpace(cardNumber.value);
    // Resets.
    isCardNumberValidState.value = false;
    inputErrorMsg.value = null;
    schemeName.value = null;

    Object.assign(cardNumberMaskRules, cardNumberInputMaskDefault);

    if (cardNumberClean.length === 0) {
      return;
    }

    if (!creditCardDetector.isPotentiallyValidScheme(cardNumberClean)) {
      inputErrorMsg.value = 'link_card_error_cardnumber_incorrect_scheme';

      return;
    }

    // Scheme is valid, configure card scheme input details.
    const scheme = creditCardDetector.liveDetectScheme(cardNumberClean);
    schemeName.value = scheme.getSchemeName;

    Object.assign(cardNumberMaskRules, {
      mask: scheme.getInputMask,
      maxLength: scheme.getInputMaskCardNumberLength,
    });

    if (!creditCardDetector.isLengthValid(cardNumberClean)) {
      // No errors are being displayed here.
      return;
    }

    // This validation includes checks for Luhn, scheme validity, and valid scheme length.
    if (!creditCardDetector.isValid(cardNumberClean)) {
      inputErrorMsg.value = 'link_card_error_cardnumber_luhn_check';
      return;
    }

    // Success: Card number and scheme are valid...
    isCardNumberValidState.value = true;
  }

  function removeCardNumberWhiteSpace(value) {
    return value.replace(/\D/g, '');
  }
</script>

<template>
  <base-floating-input
    v-model="cardNumber"
    id="card-number"
    autocomplete="cc-number"
    inputmode="numeric"
    :label="$translation('input_cardnumber_label', 'Credit card number')"
    :state-msg="stateMsg"
    :input-state-error="inputStateError"
    :input-state-success="inputStateSuccess"
    :data-maska="cardNumberMaskRules.mask"
    :max-length="`${cardNumberMaskRules.maxLength}`"
    @input="updateCardNumberOnInput"
    class="input-cardnumber-rtl"
    required
  >
    <template v-slot:icon>
      <component :is="activeLogoComponent" />
    </template>
  </base-floating-input>
</template>

<style lang="scss" scoped>
  html[dir='rtl'] {
    .input-cardnumber-rtl {
      :deep(.base-input) {
        direction: ltr;
        text-align: right;
      }
    }
  }
</style>
