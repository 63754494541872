import CreditCardSchemeAbstraction from '../credit-card-scheme-abstraction.js';

class Eftpos extends CreditCardSchemeAbstraction {
  static schemeName = 'eftpos';

  constructor(
    inputMask = '#### #### #### #### ###',
    minCardNumberLength = 16,
    maxCardNumberLength = 19,
    inputMaskDelimiter = ' ',
  ) {
    super(
      inputMask,
      minCardNumberLength,
      maxCardNumberLength,
      inputMaskDelimiter,
    );
  }

  liveDetectScheme(partialCardNumber) {
    return /^(6\d{0,18}|5[06789]\d{0,17})$/.test(partialCardNumber);
  }

  detectScheme(cardNumber) {
    return /^(6\d{15,18}|5[06789]\d{14,17})$/.test(cardNumber);
  }
}

export default Eftpos;
